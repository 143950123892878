import Head from "next/head";
import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from "next";
import { useEffect, useState } from "react";
import { isPubliclyInaccessibleSbFullSlug, SB_ROOT_SLUGS } from "@pts/storyblok";
import { FiX } from "react-icons/fi";
import type { SbMetatags, SbPageProps, SbStoryProps } from "../types";
import {
  META_DESCRIPTION_FALLBACK,
  META_IMAGE_FALLBACK,
  REVALIDATE_DURATION_SECONDS,
  META_TITLE_FALLBACK,
} from "../config/constants";
import { SbStoryContextProvider } from "../context/sb-story-context";
import { Container } from "../components/container";
import { IconButton } from "../components/button";
import { setPreviewModeFlagDuringSsr } from "../utils/preview-mode";
import { getStoryBySlugOrId } from "../api/storyblok";
import { getAllStoryLinks, isInsideStoryblokVisualEditor } from "../utils/misc";

type Params = { slug?: string[] };

export default function StoriesPage({
  story: initialStory,
  resolveRelations,
  preview,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const story = useStoryblokState(initialStory, { resolveRelations });
  if (!story) {
    return null;
  }

  const meta = getStoryMetatags(story);

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta content={meta.description} name="description" />
        <meta content={meta.openGraph.title} property="og:title" />
        <meta content={meta.openGraph.description} property="og:description" />
        <meta content={meta.openGraph.image} property="og:image" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={meta.twitter.title} property="twitter:title" />
        <meta content={meta.twitter.description} property="twitter:description" />
        <meta content={meta.twitter.image} property="twitter:image" />
        <meta
          content="PMoEsrvhcC5U-mz7IAE4P7yUgJlNqSg9vsTaf7tTElI"
          name="google-site-verification"
        />
      </Head>
      <SbStoryContextProvider story={story}>
        {!!preview && <PreviewModeAlert currentStorySlug={story.full_slug} />}
        <StoryblokComponent blok={story.content} key={story.id} />
      </SbStoryContextProvider>
    </>
  );
}

const generateMetaTitle = (
  story: SbStoryProps<{
    metatags?: SbMetatags;
  }>,
  customTitle: string | undefined,
) => {
  if (story.full_slug === SB_ROOT_SLUGS.HOME) {
    return customTitle || META_TITLE_FALLBACK;
  }

  return customTitle || `${story.name} | PTS Group`;
};

const getStoryMetatags = (
  story: SbStoryProps<{
    metatags?: SbMetatags;
  }>,
) => {
  const meta: SbMetatags | undefined = story.content.metatags;

  return {
    title: generateMetaTitle(story, story.content.metatags?.title),
    description: meta?.description || META_DESCRIPTION_FALLBACK,
    openGraph: {
      title: generateMetaTitle(story, meta?.og_title),
      description: meta?.og_description || META_DESCRIPTION_FALLBACK,
      image: meta?.og_image || META_IMAGE_FALLBACK,
    },
    twitter: {
      title: generateMetaTitle(story, meta?.twitter_title),
      description: meta?.twitter_description || META_DESCRIPTION_FALLBACK,
      image: meta?.twitter_image || META_IMAGE_FALLBACK,
    },
  };
};

function PreviewModeAlert({ currentStorySlug }: { currentStorySlug: string }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Don't show this within an iframe (Storyblok visual editor).
    if (!isInsideStoryblokVisualEditor()) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="mt-4">
      <Container>
        <div className="rounded-md bg-brand-f p-4">
          <div className="flex items-center justify-between">
            <div className="pr-4 text-white">
              <div className="text-18 font-semibold">Preview Mode</div>
              <div className="text-14 text-light-3">
                Showing latest draft (not published) content from Storyblok.{" "}
                <a
                  className="font-semibold text-white underline"
                  href={`/api/exit-preview?slug=${currentStorySlug}`}
                >
                  Exit Preview Mode
                </a>
              </div>
            </div>
            <div className="text-white">
              <IconButton
                ariaLabel="Schließen"
                icon={<FiX size={24} />}
                onClick={() => {
                  setIsVisible(false);
                }}
                size="small"
                variant="material"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export const getStaticProps: GetStaticProps<SbPageProps, Params> = async ({ params, preview }) => {
  const slug = params?.slug ? params.slug.join("/") : SB_ROOT_SLUGS.HOME;

  setPreviewModeFlagDuringSsr(preview);

  const resolveRelations = [
    "technicalInfo.infos",
    "systemsLandingPage.systems",
    "CategoriesLandingPage.categories",
    "partnersLandingPage.partners",
    "servicesLandingPage.servicePages",
    "newsOverview.news",
    "category.categoryProducts",
    "categoriesOverview.categories",
    "systemsOverview.systems",
    "servicesOverview.servicePages",
  ];

  if (!preview && isPubliclyInaccessibleSbFullSlug(slug)) {
    return {
      notFound: true,
      revalidate: REVALIDATE_DURATION_SECONDS,
    };
  }

  try {
    const { data: page } = await getStoryBySlugOrId(slug, { resolve_relations: resolveRelations });

    return {
      props: {
        story: page.story,
        resolveRelations,
        preview: preview ?? false,
      },
      revalidate: REVALIDATE_DURATION_SECONDS,
    };
  } catch (error) {
    return {
      notFound: true,
      revalidate: REVALIDATE_DURATION_SECONDS,
    };
  }
};

export const getStaticPaths: GetStaticPaths<Params> = async () => {
  const links = await getAllStoryLinks();
  const paths = links.map((link) => ({
    params: {
      slug: link.slug === "home" ? undefined : link.slug.split("/"),
    },
  }));

  return {
    paths,
    // https://nextjs.org/docs/pages/api-reference/functions/get-static-paths#fallback-blocking
    fallback: "blocking",
  };
};
